@import "../main1.scss";

.popup__wrapper {
  //position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //display: flex;
  //align-items: center;

  //overflow: hidden;
}

.popup__content {
  position: relative;
  z-index: 5;
  border-radius: 2px;
  background: black;
  width: 100%;
  min-height: 100vh; //calc(100% - 50px); //100%;
  //min-height: 100px;
  //margin: auto;
  justify-content: center;
  display: flex;
}

.popup__content h1 {
  font-family: "Nunito", sans-serif;
  margin-top: 0;
}

.popup__bg_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  opacity: 1;
  background-position: center;
  background-size: contain; //cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  animation: pulse 4s infinite;
  animation-direction: alternate;

  display: none;
  @media only screen and (min-width: #{$app-max-width}) {
    display: inherit;
  }
}

.popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  display: none;
  @media only screen and (min-width: #{$app-max-width}) {
    display: inherit;
  }
}

.popup__frame {
  //position: absolute;
  width: 100%;
  min-height: 100%;
  // top: 0;
  // left: 0;
  // right: 0;
  // margin: auto;

  z-index: 1;
  color: white;
  background-color: black;

  box-sizing: border-box;
  border: 10px solid $green;

  @media only screen and (min-width: #{$app-max-width}) {
    width: 50%;
    max-width: 650px;
  }

  //animation: glow 3s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px $green;
  }
  to {
    box-shadow: 0 0 10px 10px $green;
  }
}

.zpopup__image {
  width: 100%;
  height: 330px; //270px;

  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  @media only screen and (min-width: #{$app-max-width}) {
    height: 630px; //650px;
  }
}

.popup__frame__content {
  padding: 12px 24px;

  .buy_link_popup {
    color: $yellow;
    font-weight: bold;
    font-size: 1.5rem;
    display: inherit;
    margin-top: 12px;
  }
}

.popup__frame__headerimage {
  width: 100%;
}

.soldout {
  color: $yellow;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0 0;
}

.when_bar {
  display: flex;
  align-items: center;
  margin-top: 12px;

  .when_time {
    margin: 0; //12px 0 12px 0;
  }
}

.whens_bar {
  .whens {
    margin: 0;
  }
}

.fb-wrapper {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.fb-wrapper iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.some {
  width: 25px;
}

.some:nth-child(2n + 1) {
  margin-left: 1rem;
}

@keyframes pulse {
  0% {
    transform: skew(-1deg, -1deg) scale3d(1, 1, 1) rotate(-1deg);
  }
  100% {
    transform: skew(1deg, 1deg) scale3d(1.02, 1.02, 1) rotate(1deg);
  }
}

.artists__container {
  margin: 24px 0;
  max-height: 125px;
  overflow: hidden;
  display: inline-block;
  position: relative;

  transition: max-height 0.15s ease-in-out;

  .artists__container__header {
    .artist__name {
      position: absolute;
      top: 0; //50%;
      //transform: translateY(-50%);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      alignt-content: center;
      background-color: rgba(0, 0, 0, 0.5);

      h1 {
        text-align: center;
      }
    }
  }

  .popup__images {
    width: 100%;
  }
  .artists__content {
    margin-top: 24px;
    padding: 0 24px;
    height: 0px;
    overflow: hidden;

    .artist_contact {
      // display: flex;
      // align-items: center;

      .artist_some {
        display: inline-block;
        img {
          margin-top: 0.5rem;
          margin-right: 0.5rem;
          width: 25px;
        }
      }

      a {
        display: inherit;
        color: $green;
        text-decoration: none;
      }

      .web {
        display: block;
      }
    }
  }

  &.expanded {
    max-height: initial;
    .artists__content {
      height: auto;
    }
    .artist__name {
      display: none;
    }
  }
}

.instalink {
  color: $green;
  text-decoration: none;
}
