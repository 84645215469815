@import "../main1.scss";

.pages__container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  max-width: $app-max-width;
}

.pages__drag {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.pages__fill {
  height: 100%;
  width: 100%;
  touch-action: none;
}

.pages__fill > div {
  position: absolute;
  width: 100%;
  height: calc(100% - #{$nav-mobile-height});

  @media only screen and (min-width: #{$app-max-width}) {
    height: calc(100% - #{$nav-desktop-height});
  }
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.pages__landing {
  width:100%;
}

.pages__landing__container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pages__landing__contact-icons {
  display: flex;
  justify-content: center;
  width: 100%;
  h3 {
    margin: 0 12px;
  }
}

.pages__landing__contact-icons img {
  width: 24px;
  height: 24px;
  margin: 0 12px;
}

.pages__landing__img__container {
  padding: 2rem;
}

.pages__top__navbar__container {
  position: fixed;
  top: 0;
  right: 0;
  @media only screen and (min-width: #{$app-max-width}) {
    //left: 0;
    width: $app-max-width;
    max-width: #{$app-max-width};
    //margin: auto;
    right:inherit;
    display: flex;
    justify-content: flex-end;
  }
}

.pages__top__navbar__container img {
  cursor: pointer;
  margin-right: 1.2rem;
  margin-top: 1.2rem;
  width: 60px;
  background-color: rgba(0,0,0,0.5);

  @media only screen and (min-width: #{$app-max-width}) {
    width: 90px;
  }
}

.pages__navbar__container {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: black;

  max-width: $app-max-width;
}

.pages__navbar {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 2rem;
}

.pages__navbar div {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
      opacity: 0.75;
  }
}

.pages__navbar img {
  width: 24px;
  height: 24px;

  @media only screen and (min-width: #{$app-max-width}) {
    width: 45px;
    height: 45px;
  }
}

.pages__navbar div.active {
  opacity: 1;
}

.pages__navbar p {
  color: #44dcb5;
  margin: 0;
  font-size: 0.5rem;

  @media only screen and (min-width: #{$app-max-width}) {
    font-size: 1.5rem;
  }
}
