$app-max-width: 800px;
$nav-desktop-height: 90px;
$nav-mobile-height: 50px;

$yellow: #ffd000;
$green: #44dcb5;
$darkgreen: #17736d;

html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar {
    display:none;
}

.app-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;

  display: flex;
  justify-content: center;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Nunito', sans-serif;
}

p, a {
  font-family: 'Poppins', sans-serif;
}
