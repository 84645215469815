@import "../main1.scss";

area {
  cursor: pointer;
  border: none;
  outline-style: none;
}

.map-background {
  position: absolute;
  background-color: black;
  z-index: 10;
  padding: 0 16px;

  // display: flex;
  // align-content: center;
  // justify-content: center;
  // flex-direction: column;

  height: 100%;

  @media only screen and (min-width: #{$app-max-width}) {
    height: 100%;
  }
}

.map-image {
  //width: 100%;
  max-height: 90%;
  max-width: 100%;

  @media only screen and (min-width: #{$app-max-width}) {
    max-height: 90%;
  }
}

.map-container {
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
}

.map-instruction {
  margin: 0;
  color: $green;
  text-align: center;
}
