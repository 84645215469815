@import "../main1.scss";

.info__container {
  height: 100%;
  overflow-y: scroll;
  padding: 0 24px;
  color: white;
  b {
    color: $yellow;
  }
  @media only screen and (min-width: #{$app-max-width}) {
    margin-top: 5rem;
    height: calc(100% - 90px);
  }
}

.info__banner {
  width: 100%;
}

.kolmio__container {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  display: inline-block;
  margin-right: 0.25rem;
}

.kolmio__info {
  width: 100%;
  max-width: 25px;
}

.kolmio__r {
  width: 100%;
  max-width: 250px;
  position: absolute;
  right: 15px;
  bottom: 70px;
  @media only screen and (min-width: #{$app-max-width}) {
    max-width: 350px;
    bottom: 120px;
    right: 90px;
  }
}

.signature {
  display: inline-flex;
  height: 28px;
  align-items: center;
}

.faq {
  cursor: pointer;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  margin-top: 2rem;
  a:link, a:visited {
    color: $yellow;
  }
}

.faq__question {
  color: #44dcb5;
}

.faq__answer__image {
  width: 100%;
  @media only screen and (min-width: #{$app-max-width}) {
    width: 50%;
  }
}
