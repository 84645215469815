@import "../main1.scss";

.schedule__container {
  width: 100%;
  color: white;
  padding: 0 24px;
  b {
    color: $green;
    width: 45px;
    display: inline-block;
  }
  @media only screen and (min-width: #{$app-max-width}) {
    padding: 0 90px;
  }

  h1 {
    text-transform: uppercase;
    //color: $green;
  }

  @media only screen and (min-width: #{$app-max-width}) {
    h1 {
      //font-size: 4rem;
    }
  }
}

.schedule__container__content {
  h2 {
    margin-bottom: 0.5rem;
    //color: $darkgreen;
    //text-transform: uppercase;

    // text-decoration: underline;
    // text-decoration-style: wavy;
    // text-underline-offset: 3px;

    @media only screen and (min-width: #{$app-max-width}) {
      //font-size: 2.5rem;
    }
  }
  .schedule__stage_desc {
    @media only screen and (min-width: #{$app-max-width}) {
      //font-size: 1.5rem;
    }
  }

  .schedule__row b {
    // width: 90px;
    // flex-basis: 120px;
    // margin-right: 0.5rem;
  }

  .buy_link,
  .soldoutt {
    margin-left: 50px;

    @media only screen and (min-width: #{$app-max-width}) {
      //margin-left: 75px;
    }
  }

  .schedule__row {
    margin: 0.5rem 0;
    // display: flex;
    a,
    a:visited {
      color: $green;
      font-weight: bold;
    }
    img {
      display: none;
    }
    @media only screen and (min-width: #{$app-max-width}) {
      //font-size: 1.5rem;
      b {
        //width: 70px;
      }
    }
  }

  .schedule__link {
    cursor: pointer;
    color: $yellow;
    font-weight: bold;
    img {
      display: inline-block;
      width: 15px;
      vertical-align: middle;
    }

    @media only screen and (min-width: #{$app-max-width}) {
    }
  }
}

.schedule__buttons {
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 9999;
  display: flex;
  justify-content: space-around;
  color: $yellow;
  background-color: black;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.schedule__buttons p {
  margin: 0.5rem 0;
  opacity: 0.5;
  @media only screen and (min-width: #{$app-max-width}) {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
}

.schedule__stage_desc {
  font-size: 0.75rem;
}

.schedule__buttons p:hover {
  opacity: 0.75;
}

.confirm_selection {
  -webkit-transition: text-shadow 0.2s linear;
  -moz-transition: text-shadow 0.2s linear;
  -ms-transition: text-shadow 0.2s linear;
  -o-transition: text-shadow 0.2s linear;
  transition: text-shadow 0.2s linear;
}
.confirm_selection:hover {
  text-shadow: 0 0 10px red;
}

.schedule__buttons p.active {
  opacity: 1;
}

.soldoutt {
  color: $green;
  font-weight: bold;
}

.glow {
  cursor: pointer;
  margin-bottom: 5px;
  -webkit-animation: glow 4s ease-in-out infinite alternate;
  -moz-animation: glow 4s ease-in-out infinite alternate;
  animation: glow 4s ease-in-out infinite alternate;
}

@keyframes glow {
  40% {
    text-shadow: 0 0 30px #47d7b3, 0 0 40px #47d7b3;
  }
}
