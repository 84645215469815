@import "../main1.scss";

.sponsors__container {
  height: 100%;
  overflow: scroll;
  padding: 0 24px;
  color: white;
  b {
    color: $yellow;
  }
  @media only screen and (min-width: #{$app-max-width}) {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
}

.sponsors-list {
  list-style-type: circle;
}


.sponsors-contact {
  width: 100%;
  h3 {
    margin: 0 12px;
    display: inline-block;
  }
  img {
    width: 24px;
    height: 24px;
    margin: 6px;
    vertical-align: middle;
    @media only screen and (min-width: #{$app-max-width}) {
      margin: 0 12px;
    }
  }
}
